.mask {
    background: linear-gradient(190deg, rgba(0,0,0, 0.8) 0%, rgba(0, 0, 0, 0.75) 100%);
}

.hero-header {
    padding: 0.75rem;
    z-index: 1;
}

@media only screen and (max-width: 1023px) {
    .hero-wrapper {
    top: 200px !important;
    }
    .hero-header {
        font-size: 6rem;
    }
    .hero-sub {
        font-size: 3rem !important;
    }

}

@media only screen and (max-width: 640px) {
    .hero-wrapper {
    top: 80px !important;
    }
    .hero-header {
        font-size: 3rem;
        line-height: 50px !important;
    }
    .hero-sub {
        font-size: 2.5rem !important;
    }

    .mask {
        background: linear-gradient(190deg, rgba(0,0,0, 0.9) 0%, rgba(0, 0, 0, 0.85) 100%) !important;
    }
}

@media only screen and (max-height: 1060px) {
    .hero-wrapper {
    top: 20px !important;
    }

    .hero-header {
        font-size: 8rem;
        line-height: 50px !important;
    }

    .hero-sub {
        font-size: 3rem !important;
    }
    
    .stats-desktop .col-md-3 i {
        font-size: 2.5rem;
    }

    .stats-desktop .col-md-3 .card-title {
        font-size: 1rem;
    }

    .stats-desktop .col-md-3 .card-text {
        font-size: 0.8rem;
    }

    .mask {
        background: rgba(0, 0, 0, 0.87) !important;
    }
}
@media only screen and (max-width: 1060px) {
    .hero_text {
        font-size: 1rem !important;
        width: 100% !important;
    }
}

@media only screen and (max-height: 875px) {
    .stats-wrapper {
        display: none !important;
    }
    
    .hero_logo {
        height: 120px !important;
        margin-bottom: 5rem;
        margin-top: 2rem !important;
        width: 120px !important;
    }
}

@media only screen and (max-width: 740px) {
    .hero-header {
        font-size: 5rem;
        line-height: 50px !important;
    }
}

@media only screen and (max-height: 775px) {
    .hero-header {
        font-size: 2.5rem;
        line-height: 10px !important;
    }
        .hero-sub {
        font-size: 2rem !important;
    }
}

@media only screen and (max-width: 450px) {
    .hero-header {
        font-size: 3rem;
        line-height: 50px !important;
    }
}

@media only screen and (min-height: 1060px) {
    
    .hero_logo {
        height: 300px !important;
        width: 300px !important;
    }

}


.hero_bg-image {
    background-image: url(../../assets/hero_bg.png);
}