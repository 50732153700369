@media only screen and (min-width: 992px) {
    .stats-mobile {
        display: none !important;
    }
}


@media only screen and (max-width: 992px) {

        .bg-pan-left {
	-webkit-animation: color-change-2x 4s linear infinite alternate both;
	        animation: color-change-2x 4s linear infinite alternate both;
}
}


@media only screen and (max-width: 768px) {

    #stats-hr {
        display: none !important;
    }
}

@media only screen and (max-width: 768px) {

    .stats-mobile {
        flex-direction: row !important;
    }
}


/* ----------------------------------------------
 * Generated by Animista on 2024-8-23 14:9:27
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation color-change-2x
 * ----------------------------------------
 */
@-webkit-keyframes color-change-2x {
  0% {
    background: #c170e4;
  }
  50% {
    background: #be2467;
  }
  100% {
    background: #9024be;
  }
}
@keyframes color-change-2x {
  0% {
    background: #c170e4;
  }
  50% {
    background: #be2467;
  }
  100% {
    background: #9024be;
  }
}