@media screen and (max-width: 1040px) {
.CR_Row {
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: 5rem 0rem;
}
.col-6 {
    width: 100%;
}
}

#testimonial_image {
    margin-left: 5rem !important;
}