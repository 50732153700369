/* Login.css */

.login-container {
  padding: 2rem;
}

.MDBCard {
  background-color: white;
  border-radius: 12px;
}

.MDBInput {
  font-size: 1.1rem;
  border-radius: 8px;
}

.MDBBtn {
  width: 100%;
  font-size: 1.1rem;
  padding: 0.8rem;
  border-radius: 8px;
}

.MDBCardTitle {
  font-weight: 600;
  font-size: 1.5rem;
}

.MDBCardText {
  font-size: 1rem;
  margin-bottom: 2rem;
  color: #6c757d;
}

.login-container .card.w-50 {
    width: 100% !important;
}