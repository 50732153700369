.about_bg-image {
    background-image: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.9)), url(https://images.unsplash.com/photo-1674049406179-d7bf2c263e71?q=80&w=2700&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.bg-glass-dark {
background: rgba(110, 110, 110, 0.47);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(12.9px);
-webkit-backdrop-filter: blur(12.9px);
}

.framed {
  box-shadow:
-50px -50px 0 -40px #7b1fa2,
    50px 50px 0 -40px #7b1fa2;
}


@media screen and (max-width: 768px) {
    .p-5 {
        padding: 0.5rem !important;
    }

    .bg-glass-dark {
        max-width: 100% !important;
    }

    .about__img-wrapper {
        width: 100% !important;
        max-width: 100% !important;
    }
}