@media only screen and (max-width: 1520px) {
    .team-wrapper {
        width: 100% !important;
    }
}

@media only screen and (min-width: 1520px) {
    .team-wrapper {
        padding: 5rem;
    }
}

.vr {
    width: 1px !important;
}

.team-wrapper {
    background-image: radial-gradient(rgba(255, 64, 249, 0.4), rgba(0, 0, 0, 0.4)), url(../../assets/dddepth-320.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}