.nav {
    height: 80px !important;
}

.navbar  {
    z-index: 999 !important;
}

.nav-wrapper {
    width: 80% !important;
    border-radius: 1rem !important;
}

.bg-glass {
background: rgba(255, 255, 255, 0.2);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(12.9px);
-webkit-backdrop-filter: blur(12.9px);
}

@media only screen and (max-width: 1040px) {
    .navbar-brand img {
        height: 55px !important;
    }
    .brand-text {
        font-size: 0.9rem !important;
        color: initial;
        margin-left: 10px;
    }
    .hamburger-react {
        color: initial;
    }
}

.nav-link:hover {
    text-shadow: 0px 0px 6px white;
    transition: all 0.2s ease-in-out;
}

.nav-link{
    transition: all 0.2s ease-in-out;
}

@-webkit-keyframes swing-in-top-fwd{0%{-webkit-transform:rotateX(-100deg);transform:rotateX(-100deg);-webkit-transform-origin:top;transform-origin:top;opacity:0}100%{-webkit-transform:rotateX(0deg);transform:rotateX(0deg);-webkit-transform-origin:top;transform-origin:top;opacity:1}}@keyframes swing-in-top-fwd{0%{-webkit-transform:rotateX(-100deg);transform:rotateX(-100deg);-webkit-transform-origin:top;transform-origin:top;opacity:0}100%{-webkit-transform:rotateX(0deg);transform:rotateX(0deg);-webkit-transform-origin:top;transform-origin:top;opacity:1}}


@-webkit-keyframes swing-out-top-bck{0%{-webkit-transform:rotateX(0deg);transform:rotateX(0deg);-webkit-transform-origin:top;transform-origin:top;opacity:1}100%{-webkit-transform:rotateX(-100deg);transform:rotateX(-100deg);-webkit-transform-origin:top;transform-origin:top;opacity:0}}@keyframes swing-out-top-bck{0%{-webkit-transform:rotateX(0deg);transform:rotateX(0deg);-webkit-transform-origin:top;transform-origin:top;opacity:1}100%{-webkit-transform:rotateX(-100deg);transform:rotateX(-100deg);-webkit-transform-origin:top;transform-origin:top;opacity:0}}

/* Animations */

.navbar-menu{
    animation: swing-in-top-fwd .5s cubic-bezier(.175,.885,.32,1.275) both;
    margin-right: 0.5rem;
    border-radius: 0px 0px 16px 16px;
    z-index: 998;
    background: rgba(255, 255, 255, 0.6);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(12.9px);
    -webkit-backdrop-filter: blur(12.9px);
}

.navbar-menu.show{-webkit-animation:swing-out-top-bck .45s cubic-bezier(.6,-.28,.735,.045) both;animation:swing-out-top-bck .45s cubic-bezier(.6,-.28,.735,.045) both}