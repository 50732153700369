.CTA_wrapper {
    
}

.CTA {
position: relative;
background-image: url(../../assets/dddepth-256.jpg);
}

.form-glass {
    background: rgba(255, 255, 255, 0.346);
    border-radius: 16px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.221);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    padding: 2rem;
    }

@media only screen and (max-width: 992px) {

    .CTA_wrapper {
        flex-direction: column;
        width: 100% !important;
        margin: auto;
    }

    .cta-img-wrapper {
    background-image: none;
    width: 100% !important;
    padding: 0.5rem !important;
}

    .CTA_text-wrapper {
    background-image: none;
    width: 100% !important;
    text-align: center;
}

    .form-glass {
    padding: 1rem !important;
}

.CTA_Text {
    width: 100% !important;
    }
}

@media only screen and (max-width: 992px) {

.CTA {
margin-bottom: 2rem !important;
}
}
