.datatable {
  // scss-docs-start datatable-css-vars
  --#{$prefix}datatable-color: #{$datatable-color};
  --#{$prefix}datatable-border-color: #{$datatable-border-color};
  --#{$prefix}datatable-striped-color: #{$datatable-striped-color};
  --#{$prefix}datatable-accent-bg: #{$datatable-accent-bg};
  --#{$prefix}datatable-hover-color: #{$datatable-hover-color};
  --#{$prefix}datatable-hover-bg: #{$datatable-hover-bg};
  --#{$prefix}datatable-muted-color: #{$datatable-muted-color};
  --#{$prefix}datatable-active-color: #{$datatable-active-color};
  --#{$prefix}datatable-font-size: #{$datatable-font-size};
  --#{$prefix}datatable-background-color: #{$datatable-background-color};
  --#{$prefix}datatable-table-th-td-max-width: #{$datatable-table-th-td-max-width};
  --#{$prefix}datatable-table-th-td-padding-y: #{$datatable-table-th-td-padding-y};
  --#{$prefix}datatable-table-th-td-padding-x: #{$datatable-table-th-td-padding-x};
  --#{$prefix}datatable-thead-tr-border-width: #{$datatable-thead-tr-border-width};
  --#{$prefix}datatable-thead-th-font-weight: #{$datatable-thead-th-font-weight};
  --#{$prefix}datatable-thead-fixed-cell-background-color: #{$datatable-thead-fixed-cell-background-color};
  --#{$prefix}datatable-tbody-font-weight: #{$datatable-tbody-font-weight};
  --#{$prefix}datatable-tbody-tr-transition: #{$datatable-tbody-tr-transition};
  --#{$prefix}datatable-tbody-tr-last-child-height: #{$datatable-tbody-tr-last-child-height};
  --#{$prefix}datatable-tbody-loader-height: #{$datatable-tbody-loader-height};
  --#{$prefix}datatable-tbody-progress-animation: #{$datatable-tbody-progress-animation};
  --#{$prefix}datatable-tbody-progress-width: #{$datatable-tbody-progress-width};
  --#{$prefix}datatable-tbody-progress-opacity: #{$datatable-tbody-progress-opacity};
  --#{$prefix}datatable-tbody-progress-border-radius: #{$datatable-tbody-progress-border-radius};
  --#{$prefix}datatable-pagination-padding-y: #{$datatable-pagination-padding-y};
  --#{$prefix}datatable-pagination-border-width: #{$datatable-pagination-border-width};
  --#{$prefix}datatable-pagination-nav-font-size: #{$datatable-pagination-nav-font-size};
  --#{$prefix}datatable-pagination-buttons-margin-left: #{$datatable-pagination-buttons-margin-left};
  --#{$prefix}datatable-pagination-button-padding-x: #{$datatable-pagination-button-padding-x};
  --#{$prefix}datatable-sort-icon-transition-duration: #{$datatable-sort-icon-transition-duration};
  --#{$prefix}datatable-sort-icon-left: #{$datatable-sort-icon-left};
  --#{$prefix}datatable-sort-icon-top: #{$datatable-sort-icon-top};
  --#{$prefix}datatable-select-wrapper-font-size: #{$datatable-select-wrapper-font-size};
  --#{$prefix}datatable-select-wrapper-font-weight: #{$datatable-select-wrapper-font-weight};
  --#{$prefix}datatable-sm-th-td-padding-y: #{$datatable-sm-th-td-padding-y};
  --#{$prefix}datatable-sm-th-td-padding-x: #{$datatable-sm-th-td-padding-x};
  --#{$prefix}datatable-sm-tbody-tr-last-child-height: #{$datatable-sm-tbody-tr-last-child-height};
  --#{$prefix}datatable-sm-pagination-padding: #{$datatable-sm-pagination-padding};
  --#{$prefix}datatable-bordered-th-td-border-width: #{$datatable-bordered-th-td-border-width};
  --#{$prefix}datatable-hover-tbody-tr-transition: #{$datatable-hover-tbody-tr-transition};
  --#{$prefix}datatable-dark-select-arrow-input-color: #{$datatable-dark-select-arrow-input-color};
  --#{$prefix}datatable-dark-border-color: #{$datatable-dark-border-color};
  --#{$prefix}datatable-dark-check-border-color: #{$datatable-dark-check-border-color};
  --#{$prefix}datatable-dark-datatable-progress-opacity: #{$datatable-dark-datatable-progress-opacity};
  // scss-docs-end datatable-css-vars

  font-size: var(--#{$prefix}datatable-font-size);
  color: var(--#{$prefix}datatable-color);
  background-color: var(--#{$prefix}datatable-background-color);

  &-inner {
    background-color: inherit;
  }

  table {
    font-size: var(--#{$prefix}datatable-font-size);
    color: var(--#{$prefix}datatable-color);
    background-color: inherit;
    margin-bottom: 0;
    border-color: var(--#{$prefix}datatable-border-color);

    th,
    td {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: var(--#{$prefix}datatable-table-th-td-max-width);
      padding: var(--#{$prefix}datatable-table-th-td-padding-y) var(--#{$prefix}datatable-table-th-td-padding-x);
    }
  }

  thead {
    background-color: inherit;

    tr {
      background-color: inherit;
      border-bottom: var(--#{$prefix}datatable-thead-tr-border-width) solid var(--#{$prefix}datatable-border-color);
    }
    th {
      position: relative;
      border-bottom: none;
      font-weight: var(--#{$prefix}datatable-thead-th-font-weight);

      &:hover {
        .datatable-sort-icon {
          opacity: 1;
        }
      }
    }

    .fixed-cell {
      position: sticky;
      top: 0;
      z-index: 3;
      box-shadow: var(--#{$prefix}datatable-border-color) 0px 1px;
      background-color: var(--#{$prefix}datatable-thead-fixed-cell-background-color);
    }
  }

  tbody {
    font-weight: var(--#{$prefix}datatable-tbody-font-weight);
    background-color: inherit;

    .fixed-cell {
      position: sticky;
      z-index: 1;
      background-color: inherit;
    }

    tr {
      background-color: inherit;
      transition: var(--#{$prefix}datatable-tbody-tr-transition);

      &:last-child {
        border-bottom: transparent;
        height: var(--#{$prefix}datatable-tbody-tr-last-child-height);
      }

      &:focus {
        outline: none;
      }

      &.active {
        background-color: var(--#{$prefix}datatable-active-color);
      }
    }

    td {
      &:focus {
        outline: none;
      }
    }
  }

  &-loader {
    width: 100%;
    height: var(--#{$prefix}datatable-tbody-loader-height);
    position: relative;
    overflow: hidden;
  }

  &-loader-inner {
    display: block;
    height: 100%;
  }

  &-progress {
    animation: var(--#{$prefix}datatable-tbody-progress-animation);
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    width: var(--#{$prefix}datatable-tbody-progress-width);
    position: relative;
    opacity: var(--#{$prefix}datatable-tbody-progress-opacity);
    border-radius: var(--#{$prefix}datatable-tbody-progress-border-radius);
    display: block;
    height: 100%;
  }

  @keyframes datatableProgress {
    0% {
      left: -45%;
    }
    100% {
      left: 100%;
    }
  }

  &-pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: var(--#{$prefix}datatable-pagination-padding-y) 0;
    border-top: var(--#{$prefix}datatable-pagination-border-width) solid var(--#{$prefix}datatable-border-color);
  }

  &-pagination-nav {
    padding: 0;
    font-size: var(--#{$prefix}datatable-pagination-nav-font-size);
    direction: ltr #{'/*!rtl:ignore*/'};
  }

  &-pagination-buttons {
    margin-left: var(--#{$prefix}datatable-pagination-buttons-margin-left);
  }

  &-pagination-button {
    padding-left: var(--#{$prefix}datatable-pagination-button-padding-x);
    padding-right: var(--#{$prefix}datatable-pagination-button-padding-x);
    color: var(--#{$prefix}datatable-color);
  }

  &-sort-icon {
    opacity: 0;
    color: var(--#{$prefix}datatable-muted-color);
    cursor: pointer;
    transition-property: opacity, transform;
    transition-duration: var(--#{$prefix}datatable-sort-icon-transition-duration);
    transition-timing-function: linear;
    position: absolute;
    left: var(--#{$prefix}datatable-sort-icon-left);
    top: var(--#{$prefix}datatable-sort-icon-top);

    &.active {
      opacity: 1;
      color: var(--#{$prefix}datatable-color);
    }
  }

  &-select-wrapper {
    display: flex;
    align-items: center;
    font-size: var(--#{$prefix}datatable-select-wrapper-font-size);
    font-weight: var(--#{$prefix}datatable-select-wrapper-font-weight);
    padding: 0 1rem;

    .select-input {
      max-width: 70px;
    }
  }

  &-select-text {
    margin: 0 1rem;
  }

  &.datatable-sm {
    th,
    td {
      padding: var(--#{$prefix}datatable-sm-th-td-padding-y) var(--#{$prefix}datatable-sm-th-td-padding-x);
    }

    tbody {
      tr:last-child {
        border-bottom: transparent;
        height: var(--#{$prefix}datatable-sm-tbody-tr-last-child-height);
      }
    }

    .datatable-pagination {
      padding: var(--#{$prefix}datatable-sm-pagination-padding) 0;
    }
  }

  &.datatable-bordered {
    th,
    td {
      border-width: var(--#{$prefix}datatable-bordered-th-td-border-width);
    }

    .datatable-pagination {
      border: var(--#{$prefix}datatable-bordered-th-td-border-width) solid var(--#{$prefix}datatable-border-color);
    }
  }

  &.datatable-striped {
    tbody {
      tr:nth-of-type(odd) {
        background-color: var(--#{$prefix}datatable-accent-bg);
        color: var(--#{$prefix}datatable-striped-color);
      }
    }
  }

  &.datatable-hover {
    tbody {
      tr {
        transition: var(--#{$prefix}datatable-hover-tbody-tr-transition) !important;

        &:hover {
          background-color: var(--#{$prefix}datatable-hover-bg) !important;
        }
      }
    }
  }

  &.datatable-clickable-rows {
    tbody {
      tr {
        cursor: pointer;

        &.active {
          background-color: var(--#{$prefix}datatable-hover-bg);
        }
      }
    }
  }

  &.datatable-dark {
    --#{$prefix}datatable-color: #{$datatable-dark-color};
    --#{$prefix}datatable-border-color: #{$datatable-dark-border-color};
    --#{$prefix}datatable-active-color: #{$datatable-dark-active-color};
    --#{$prefix}datatable-striped-color: #{$datatable-dark-striped-color};
    --#{$prefix}datatable-accent-bg: #{$datatable-dark-accent-bg};
    --#{$prefix}datatable-hover-bg: #{$datatable-dark-hover-bg};
    --#{$prefix}datatable-hover-color: #{$datatable-dark-hover-color};

    .select-wrapper {
      .select-arrow,
      input {
        color: var(--#{$prefix}datatable-dark-select-arrow-input-color);
      }

      .form-outline .form-control:focus ~ .form-notch .form-notch-leading,
      .form-notch-trailing {
        border-color: var(--#{$prefix}datatable-dark-border-color) !important;
      }
      .form-notch-leading,
      .form-notch-middle,
      .form-notch-trailing {
        border-color: var(--#{$prefix}datatable-dark-border-color) !important;
      }

      .form-outline .form-control:focus ~ .form-notch .form-notch-leading {
        border-color: var(--#{$prefix}datatable-dark-border-color) !important;
      }
    }

    .form-check-input[type='checkbox']:checked {
      background-color: transparent;
      border-color: var(--#{$prefix}datatable-dark-check-border-color);
    }

    .datatable-progress {
      opacity: var(--#{$prefix}datatable-dark-datatable-progress-opacity);
    }
  }

  @each $color, $value in $theme-colors {
    &.border-#{$color} {
      --#{$prefix}datatable-border-color: #{$value};
    }
  }

  &.datatable-borderless {
    --#{$prefix}datatable-border-color: #{transparent};
  }

  &.datatable-loading {
    color: var(--#{$prefix}datatable-muted-color);

    th {
      color: var(--#{$prefix}datatable-muted-color);
    }

    .datatable-sort-icon {
      display: none;
    }
  }
}

@media (max-width: 520px) {
  .datatable-pagination {
    flex-direction: column-reverse;

    &-nav {
      margin: 0.8rem 0;
    }

    &-button {
      font-size: 1rem;
    }

    &-buttons {
      margin: 0.8rem 0;
    }
  }
}
